<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <h3 class="card-title">{{ loggerName }} - Period Levels</h3>
      </div>
      <div class="row">
        <div class="col">
          <div ref="chart">

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <table class="table">
            <thead>
            <tr>
              <th>Time</th>
              <th v-for="descriptor in descriptors" :key="'header-' + descriptor">{{ descriptor }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="value in reversedLevelsAndTimestamps" :key="value.timestamp">
              <td>{{ formatTimestamp(value.timestamp * 1000) }}</td>
              <td v-for="descriptor in descriptors" :key="value.timestamp + descriptor">
                {{ value[descriptor].toFixed(1) }}
              </td>
            </tr>

            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import ApexCharts from "apexcharts";
import {ref} from "vue";

export default {
  name: "DatasetTable",
  props: {
    dataset: Object,
  },
  data() {
    return {
      chart: ref(null)
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    dataset: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      let date = new Date(timestamp);
      return date.toLocaleString();
    },
    initChart() {
      let vm = this;

      const options = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 10
            }
          },

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          type: 'datetime',
          labels:{
             datetimeUTC: false
          }
        },
        series: [{
          name: "LCeq",
          color: "#00b3e3",
          data: []
        }],
      };

      this.chart = new ApexCharts(vm.$refs.chart, options);
      this.chart.render();

      this.updateChart();
    },
    updateChart() {
      let vm = this;

      let series = [];
      let data = [];

      data = vm.sortedLevelsAndTimestamps.map((value) => ({
        x: new Date(value.timestamp * 1000),
        y: value["LCeq"].toFixed(2)
      }));


      series.push({
        name: "LCeq",
        data: data
      });

      vm.chart.updateSeries(series);

    },
  },
  computed: {
    loggerName() {
      return this.dataset.name;
    },
    descriptors() {
      return Object.keys(this.samples);
    },
    timestamps() {
      // Create a 1d array of all timestamps
      let timestamps = [];
      for (let timestamp_list of this.dataset.timestamps) {
        for (let timestamp of timestamp_list) {
          timestamps.push(timestamp);
        }
      }


      return timestamps;

    },
    samples() {
      let samples = {};
      for (let dataset of this.dataset.datasets) {
        for (let [descriptor, values] of Object.entries(dataset.samples)) {
          if (!samples[descriptor]) {
            samples[descriptor] = [];
          }
          for (let value of values) {
            samples[descriptor].push(value);
          }
        }
      }

      return samples;
    },
    orderedIndex() {
      return this.timestamps.map((e, i) => i).reverse();
    },
    levels() {
      return this.samples.LCeq || [];
    },
    sortedLevelsAndTimestamps() {
      let vm = this;

      let combinedLevelsTimestamps = vm.timestamps.map((timestamp, i) => {
        const combined = {timestamp}
        for (let descriptor of vm.descriptors) {
          combined[descriptor] = vm.samples[descriptor][i];
        }
        return combined;
      });
      combinedLevelsTimestamps.sort((a, b) => a.timestamp - b.timestamp);

      return combinedLevelsTimestamps;
    },
    reversedLevelsAndTimestamps() {
      // Return a copy of the array with the elements reversed
      return this.sortedLevelsAndTimestamps.slice().reverse();
    },
  },
}
</script>

<style scoped>

</style>