<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <h3 class="card-title">{{ loggerName }} - Real Time Levels</h3>
      </div>
      <div class="row">
        <div class="col">
          <div ref="chart" style="min-height: 288px;">

          </div>
        </div>
        <div class="col-md-auto">
          <div class="divide-y divide-y-fill">
            <div class="px-3">
              <div class="text-muted">
                <span class="status-dot bg-primary"></span> LCeq,fast
              </div>
                            <div class="h2">{{ fastLevel }} dB</div>
            </div>

            <div class="px-3">
              <div class="text-muted">
                <span class="status-dot bg-primary"></span> LCeq,T
              </div>
                            <div class="h2">{{ periodLevel }} dB</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ref} from "vue";
import ApexCharts from "apexcharts";

export default {
  name: "RealtimeChart",
  data() {
    return {
      chart: ref(null)
    }
  },
  mounted() {
    this.initChart();
  },
  unmounted() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  props: {
    loggerName: String,
    buffer: Array,
    timestamps: Array
  },
  computed: {
    levels() {
      let levels = this.buffer.map((e) => {
        return e.LCeq;
      });

      return levels;
    },
    descriptors() {
      return Object.keys(this.dataset.samples);
    },
    periodLevel() {
      // log add levels
      let sum = 0;
      for (let i = 0; i < this.levels.length; i++) {
        sum += Math.pow(10, this.levels[i] / 10);
      }
      let level = 10 * Math.log10(sum / this.levels.length);

      return level.toFixed(1);
    },
    fastLevel() {
      if (this.levels.length > 0) {
        return this.levels[this.levels.length - 1].toFixed(1);
      } else {
        return 0;
      }
    },
  },
  watch: {
    buffer: {
      handler: function () {
        if (this.chart) {
          this.updateChart();
        }
      },
      deep: true
    }
  },
  methods: {
    initChart() {
      let vm = this;

      const options = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 10
            }
          },

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          type: 'datetime',
          labels:{
             datetimeUTC: false
          }
        },
        series: [{
          name: "LCeq",
          color: "#00b3e3",
          data: []
        }],
      };

      this.chart = new ApexCharts(vm.$refs.chart, options);
      this.chart.render();

      this.updateChart();
    },
    updateChart() {
      let vm = this;





      let series = [];
      let data = [];
      for (let [index, value] of Object.entries(vm.levels)) {
        data.push({
          x: new Date(vm.timestamps[index] * 1000),
          y: value.toFixed(2)
        });
      }

      series.push({
        name: "LCeq",
        data: data
      });

      vm.chart.updateSeries(series);

    },
  }
}
</script>

<style scoped>

</style>