import { createRouter, createWebHistory } from 'vue-router'
import LoggerDashboardView from '../views/LoggerDashboardView.vue'
import EventConfigView from "@/views/EventConfigView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoggerDashboardView
  },
  {
    path: '/config',
    name: 'config',
    component: EventConfigView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
