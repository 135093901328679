<template>

  <div class="container">
    <div class="row" v-if="!isLive">
      <div class="col">
        <h1>Not currently logging</h1>
      </div>
    </div>
    <div class="row">
      <div class="col" v-for="(logger, index) in loggers"
           :key="'realtime-' + index">
        <RealtimeChart
            :loggerName="logger"
            :buffer="realtime.buffers[index]"
            :timestamps="realtime.timestamps[index]"/>
      </div>
    </div>

    <div class="row mt-3" v-if="datasets" >
      <div class="col" v-for="(logger, index) in loggers" :key="'dataset-' + index">
        <DatasetTable
            :dataset="datasets[index]"/>

      </div>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";
import RealtimeChart from "@/components/RealtimeChart.vue";
import DatasetTable from "@/components/DatasetTable.vue";

export default {
  name: 'LoggerDashboardView',
  components: {DatasetTable, RealtimeChart},
  data() {
    return {
      isLive: ref(true),
      realtime: ref({}),
      charts: ref({}),
      datasets: ref(null)
    }
  },
  computed: {
    loggers() {
      return this.realtime.loggers || [];
    },
  },
  mounted() {
    this.init();
  },
  unmounted() {
    this.stop();
  },
  methods: {
    stop() {
      this.isLive = false;
    },
    init() {
      let vm = this;
      vm.updateRealtime();
      vm.updateDatasets();
    },
    async updateRealtime() {
      let vm = this;
      if (vm.isLive) {
        let timeout = 1000;
        try {
          const response = await fetch(this.$apiUrl + '/realtime');
          vm.realtime = await response.json();
          vm.isLive = vm.realtime.is_active;
        } catch (e) {
          console.log(e);
          timeout = 30000
        }

        setTimeout(() => {
          vm.updateRealtime();
        }, timeout)
      }
    },
    async updateDatasets() {
      let vm = this;
      if (vm.isLive) {
        try {

          const response = await fetch(this.$apiUrl + '/datasets');
          vm.datasets = await response.json();
        } catch (e) {
          console.log(e);
        }

        setTimeout(() => {
          vm.updateDatasets();
        }, 30000)
      }
    },
  }
}
</script>
