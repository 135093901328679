<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Event Config</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div v-if="isActive">
                <h2>Currently Logging</h2>
                <button class="btn btn-primary" @click="stopLogging()">Stop</button>
              </div>

              <div v-else>
                <h2>Not Logging</h2>
                <button class="btn btn-primary" @click="startLogging()">Start</button>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12">
                <h3>Add Logger</h3>
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" id="name" v-model="logger.name" placeholder="Enter name">
                </div>
                <div class="form-group">
                  <label for="host">Host</label>
                  <input type="text" class="form-control" id="host" v-model="logger.host" placeholder="Enter host">
                </div>
                <div class="form-group">
                  <label for="ftp_user">FTP User</label>
                  <input type="text" class="form-control" id="ftp_user" v-model="logger.ftp_user"
                         placeholder="Enter FTP User">
                </div>
                <div class="form-group">
                  <label for="trigger_level">Trigger level - dB(C)</label>
                  <input type="text" class="form-control" id="trigger_level" v-model="logger.trigger_level"
                         placeholder="0.0">
                </div>
                <div class="form-group">
                  <label for="ftp_password">FTP Password</label>
                  <input type="text" class="form-control" id="ftp_password" v-model="logger.ftp_password"
                         placeholder="Enter FTP Password">
                </div>
                <div class="form-group">
                  <label for="ftp_port">FTP Port</label>
                  <input type="number" class="form-control" id="ftp_port" v-model="logger.ftp_port"
                         placeholder="Enter FTP Port">
                </div>
                <div class="form-group">
                  <label for="ftp_port">HTTP Port</label>
                  <input type="number" class="form-control" id="http_port" v-model="logger.http_port"
                         placeholder="Enter HTTP Port">
                </div>
                <div class="form-group">
                  <label for="timezone">Event Start</label>
                  <input type="datetime-local" class="form-control" id="event_start" v-model="logger.event_start"
                         placeholder="Event start">
                </div>
                <div class="form-group">
                  <label for="timezone">Timezone</label>
                  <input type="text" class="form-control" id="timezone" v-model="logger.timezone"
                         placeholder="Enter Timezone">
                </div>
                <button :disabled="isActive" type="submit" class="btn btn-primary mt-3" @click="addLogger(logger)">Add Logger</button>
                <p v-if="isActive" class="text-info">You can't add / remove a logger when the logger is active.  Press Stop to make changes to the loggers.</p>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h3>Loggers</h3>
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Host</th>
                    <th scope="col">Trigger level</th>
                    <th scope="col">FTP User</th>
                    <th scope="col">FTP Password</th>
                    <th scope="col">FTP Port</th>
                    <th scope="col">HTTP Port</th>
                    <th scope="col">Event start</th>
                    <th scope="col">Timezone</th>
                    <th scope="col">Actions</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="logger in loggers" :key="logger.id">
                    <td>{{ logger.name }}</td>
                    <td>{{ logger.host }}</td>
                    <td>{{ logger.trigger_level }} dB(C)</td>
                    <td>{{ logger.ftp_user }}</td>
                    <td>{{ logger.ftp_password }}</td>
                    <td>{{ logger.ftp_port }}</td>
                    <td>{{ logger.http_port }}</td>
                    <td>{{ logger.event_start }}</td>
                    <td>{{ logger.timezone }}</td>
                    <td>
                      <button class="btn btn-danger" @click="removeLogger(logger)" :disabled="isActive">Delete</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {ref} from "vue";
import Swal from "sweetalert2";
import {DateTime} from "luxon";

function createNewLogger() {
  return {
    name: "",
    host: "",
    ftp_user: "",
    ftp_password: "",
    ftp_port: 21,
    http_port: 80,
    trigger_level: 110.5,
    event_start: DateTime.local().toISO().substring(0, 16),
    timezone: "Australia/Adelaide",
  }
}

//
// function createNewEvent() {
//
//   return {
//     name: '',
//     loggers: [],
//   }
// }

export default {
  name: 'EventConfigView',
  components: {},
  data() {
    return {
      isActive: ref(false),
      loggers: ref([]),
      logger: ref(createNewLogger()),
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let vm = this;
      vm.getLoggers();
      vm.getIsActive();
    },
    async getLoggers() {
      let vm = this;
      let response = await fetch(this.$apiUrl + '/loggers');
      let data = await response.json();

      console.log(data);
      vm.loggers = data;
    },
    async getIsActive() {
      let vm = this;
      let response = await fetch(this.$apiUrl + '/is_active');
      let data = await response.json();
      vm.isActive = data.is_active;
    },
    async addLogger(logger) {
      let vm = this;

      let response = await fetch(this.$apiUrl + '/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(logger)
      });

      let data = await response.json();
      // Check if "error" key in data
      if (data.error) {
        // Show sweet alert 2 error
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.error,
        });

        return;
      }
      vm.loggers.push(data);
    },
    async removeLogger(logger) {
      let vm = this;

      await fetch(this.$apiUrl + '/delete/' + logger.host, {
        method: 'DELETE',
      });

      // remove logger for loggers
      vm.loggers = vm.loggers.filter((item) => {
        return item.host !== logger.host || item.http_port !== logger.http_port;
      });
    },
    async startLogging() {
      let vm = this;

      await fetch(this.$apiUrl + '/start', {
        method: 'POST',
      });

      vm.isActive = true;
    },
    async stopLogging() {
      let vm = this;

      await fetch(this.$apiUrl + '/stop', {
        method: 'POST',
      });

      vm.isActive = false;
    },
  }
}
</script>
